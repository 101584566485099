import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import ArrowForwardIcon from "@mui/icons-material/Forward"
import ArrowForwardThinIcon from "@mui/icons-material/ArrowForward"
import BreadCrumbs from "../components/bread-crumbs"
import "./about.scss"
import "./Inner-cta.scss"

const breadCrumbsList = [
  {
    title: "Home",
    link: "/",
  },
]

class AboutUs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
    }
  }

  componentDidMount() {
    window.analytics.page("About")
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="About" />
        <BreadCrumbs currentPage={"About"} breadCrumbsList={breadCrumbsList} />
        <div
          className="common-detailed-header "
          style={{ position: "relative" }}
        >
          <div className="common-detailed-inner">
            <div className="common-detailed-pattern-content">
              <h2 className="common-detailed-main-head">
                We strive to be better than the drug dealers.
              </h2>
              <ul className="common-detailed-ul">
                <li>
                  <img
                    src={require("../assets/images/checkmark-circle-outlined.svg")}
                    alt="icon"
                  />
                  <p>Available around the clock, by text</p>
                </li>
                <li>
                  <img
                    src={require("../assets/images/checkmark-circle-outlined.svg")}
                    alt="icon"
                  />
                  <p>Same-day service</p>
                </li>
                <li>
                  <img
                    src={require("../assets/images/checkmark-circle-outlined.svg")}
                    alt="icon"
                  />
                  <p>Transparent pricing and consistent experience </p>
                </li>
              </ul>
              <Button
                id="submit-email"
                className="blue-arrow-btn"
                href={"/get-started/"}
                endIcon={<ArrowForwardIcon />}
              >
                <span className="button-label">Get Started</span>
              </Button>
            </div>
            <div className="common-detailed-image-side">
              <img
                className="common-detailed-drug-img"
                src={require("../assets/images/drugdealers.svg")}
                alt="Drug Dealer"
              />
            </div>
          </div>
        </div>
        <div className="new-home-manifesto-wrapper">
          <div className="new-home-manifesto-inner"></div>
          <h2 className="new-home-manifesto-text">
            There is no question about it, accessing drugs has been an easier
            and better experience than accessing traditional behavioral health
            services.
          </h2>
          <Button href="/manifesto/" className="new-blue-link" color="primary">
            Read our Manifesto <ArrowForwardThinIcon />
          </Button>
        </div>
        <div className="mission-wrapper">
          <div className="mission-inner">
            <div className="mission-single-feature">
              <div className="mission-img-side">
                <img
                  className="mission-image"
                  src={require("../assets/images/juggling.svg")}
                  alt="Frame"
                />
              </div>
              <div className="mission-text-side">
                <h2 className="mission-heading">Our Mission</h2>
                <h2 className="mission-sub-heading">
                  We’re here to help people establish the mindset, habits, and
                  skills to thrive.
                </h2>
                <p className="mission-para">
                  We created a model that pairs incredible providers who deliver
                  evidence-based interventions with digitized behavioral-health
                  resources. Our approach maximizes the impact of high-quality
                  care with minimum overhead and without traditional marketing
                  techniques. We transfer the savings directly to our clients –
                  making our care better, more affordable, and more convenient.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="about-person-wrapper">
          <div className="about-person-pattern"></div>
          <div className="list-gradient-bg">
            <div className="about-person-inner">
              <h2 className="about-person-heading">How We’re Different</h2>
              <p className="about-person-sub-heading">
                Of the 20+million Americans who could benefit from clinical
                support for their drug or alcohol use,{" "}
                <span>95% don’t think they need it.</span>
              </p>
              <img
                className="about-person-image"
                src={require("../assets/images/person-Illustration.svg")}
                alt="illustration"
              />
              <p className="about-person-sub-heading">
                We’re here to show them what’s possible.
              </p>
            </div>
            <div className="current-confidant-wrapper">
              <div className="current-system">
                <h3 className="current-confidant-heading">
                  The Current System
                </h3>
                <ul>
                  <li>
                    <img
                      src={require("../assets/images/minus-icon.svg")}
                      alt="Minus"
                    />
                    <p>Expensive</p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/minus-icon.svg")}
                      alt="Minus"
                    />
                    <p>Ineffective, outdated practices</p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/minus-icon.svg")}
                      alt="Minus"
                    />
                    <p>Disrupts your life</p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/minus-icon.svg")}
                      alt="Minus"
                    />
                    <p>Shame-slinging</p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/minus-icon.svg")}
                      alt="Minus"
                    />
                    <p>Inaccessible and hard to navigate</p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/minus-icon.svg")}
                      alt="Minus"
                    />
                    <p>Focus on the drug / abstinence</p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/minus-icon.svg")}
                      alt="Minus"
                    />
                    <p>Requires you to hit rock bottom</p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/minus-icon.svg")}
                      alt="Minus"
                    />
                    <p>Requires that you want to make major changes</p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/minus-icon.svg")}
                      alt="Minus"
                    />
                    <p>Designed to maximize revenue</p>
                  </li>
                </ul>
              </div>
              <div className="confidant-system">
                <h3 className="current-confidant-heading">Confidant Health</h3>
                <ul>
                  <li>
                    <img
                      src={require("../assets/images/CHMascotte.svg")}
                      alt="Alfie"
                    />
                    <p>Transparent and affordable </p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/CHMascotte.svg")}
                      alt="Alfie"
                    />
                    <p>Cutting edge and tried and true best practices </p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/CHMascotte.svg")}
                      alt="Alfie"
                    />
                    <p>Fits into your schedule </p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/CHMascotte.svg")}
                      alt="Alfie"
                    />
                    <p>Accepting and judgement free </p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/CHMascotte.svg")}
                      alt="Alfie"
                    />
                    <p>Access it with assistance </p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/CHMascotte.svg")}
                      alt="Alfie"
                    />
                    <p>Focus on you and your goals </p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/CHMascotte.svg")}
                      alt="Alfie"
                    />
                    <p>Ready to help you whenever you’re curious </p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/CHMascotte.svg")}
                      alt="Alfie"
                    />
                    <p>Helps you set realistic goals</p>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/CHMascotte.svg")}
                      alt="Alfie"
                    />
                    <p>Designed to maximize your experience</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="about-services-wrapper">
          <div className="about-services-wave-inner">
            <h2 className="about-services-wave-heading">Our Services</h2>
            <p className="about-services-wave-para">
              We specialize in helping people and families that are impacted by
              drug use and <br />
              addiction, and all that comes with it. Confidant therapists,
              prescribers, and <br />
              coaches are trained to offer evidence-based best practices that
              treat <br />
              the whole person.
            </p>
            <div className="wave-service-list">
              <div className="single-wave-service-box">
                <img
                  className="new-feature-image"
                  src={require("../assets/images/provider-prescriber.svg")}
                  alt="Providers & Prescriber"
                />
                <Button
                  href="/online-therapy/"
                  className="new-blue-link"
                  color="primary"
                >
                  Providers & Prescribers <ArrowForwardThinIcon />
                </Button>
              </div>

              <div className="single-wave-service-box">
                <img
                  className="new-feature-image"
                  src={require("../assets/images/free-support.svg")}
                  alt="Support Groups"
                />
                <Button
                  href="/support-groups/"
                  className="new-blue-link"
                  color="primary"
                >
                  Support Groups <ArrowForwardThinIcon />
                </Button>
              </div>

              <div className="single-wave-service-box">
                <img
                  className="new-feature-image"
                  src={require("../assets/images/self-directed.svg")}
                  alt="Self-directed Courses"
                />
                <Button
                  href="/how-to-guides/"
                  className="new-blue-link"
                  color="primary"
                >
                  Self-directed Courses <ArrowForwardThinIcon />
                </Button>
              </div>
            </div>
          </div>

          <div className="new-community-model-wrapper">
            <div className="new-community-model-inner">
              <h2 className="new-community-model-text">
                Confidant Community Payment Model
              </h2>
              <p className="community-payment-description">
                Our "Pay it Forward" approach enables people to access our
                services for as little as $1 Because your income shouldn't
                dictate your future.
              </p>
              <Button
                href="/community-payment/"
                className="new-blue-link"
                color="primary"
              >
                Read about the community payment model
              </Button>
              <img
                className="community-model-image"
                src={require("../assets/images/community-image1.png")}
                alt="Community Model Image"
              />
            </div>
          </div>

          <div className="behaviour-health-box">
            <div className="behaviour-health-inner">
              <h3 className="behaviour-health-head">
                We’re ushering in the future of behavioral health
              </h3>
              <p className="behaviour-health-para">
                Confidant is a community of integrated behavioral health
                providers and digital healthcare experts that use technology to
                maximize their impact.{" "}
              </p>
              <Button
                href={"/meet-the-team/"}
                className="new-blue-link"
                color="primary"
              >
                Meet the Team <ArrowForwardThinIcon />
              </Button>
            </div>
          </div>
        </div>

        <Modal
          className="modalStyle1"
          open={
            this.state.openModal ||
            (typeof window !== "undefined" &&
              window.location &&
              window.location.hash === "#get-started")
          }
          disableScrollLock={true}
        >
          <div />
        </Modal>
      </Layout>
    )
  }
}

export default AboutUs
